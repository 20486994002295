<script>
import { api } from "@/state/services";

export default {
  locales: {
    pt: {
      'Buy': 'Comprar',
    },
    es: {
      'Buy': 'Comprar',
    }
  },
  props: {
    id: {
      default: ""
    },
    variations: {
      default: ""
    },
  },
  components: {},
  data() {
    return {
      loading: false,
      variation: Object.keys(this.variations).shift(),
      selected: true,
    };
  },
  methods: {
    buyProduct(variation) {
      if (variation) {
        this.loading = true

        api
          .post('store/cart', {
            id: this.variation,
            qty: 1,
            plus: 'yes'
          })
          .then(response => {
            if (response.data.status == 'success') {
              this.$toast.success('O produto foi adicionado em seu carrinho.')

              localStorage.removeItem('cart');
              this.$parent.getCart()
            } else {
              this.$toast.error('Não foi possível adicionar o produto em seu carrinho, tente novamente.')
            }

            this.loading = false
          })
          .catch(error => {
            if (error) {
              this.$toast.error('Não foi possível adicionar o produto em seu carrinho, tente novamente.')
            }
          })
      }
    }
  }
};
</script>

<template>
  <b-form @submit.prevent="buyProduct(id)">
    <select v-if="Object.keys(this.variations).length > 1" v-model="variation" class="custom-select mb-2">
      <option v-for="option in variations" :key="option.id" :value="option.id" :selected="[variation == null ? variation = option.id : false]">{{ option.name }}</option>
    </select>
    <b-button class="btn-block text-uppercase" type="submit" variant="default" :disabled="loading">
      {{ t('Buy') }}
      <b-spinner v-if="loading" small class="ml-2 align-middle" variant="white" role="status"></b-spinner>
    </b-button>
  </b-form>
</template>